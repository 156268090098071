import React from 'react';

const Thumbnail = ({ imgSrc, classes }) => {
    return (

        <figure className={classes}>
            <img src={imgSrc} alt="Tata Bundle" />
        </figure>
    );
};

export default Thumbnail;